import { useEffect } from "react";
import "../CoinWallet/index.css";
import "./index.css";
import withDashboard from "../../HOC/withDashboard";
import BtcImg from "../../assets/bitcoin.png";
import EthImg from "../../assets/ethereum.png";
import UsdtImg from "../../assets/usdt.png";
import { Button } from "@mui/material";
import Chart from "../../components/Chart";
import CardLayout from "../../components/CardLayout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PriceCardList from "../../components/PriceCardList";
import { estimateBTCGas } from "../../redux/actions/btcAction";
import { estimateETHGas } from "../../redux/actions/ethAction";
import { estimateUSDTGas } from "../../redux/actions/usdtAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { formatNumberWithCommas } from "../../utils";

const ReviewTransaction = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Btc = useSelector(({ Btc }) => Btc);
  const Eth = useSelector(({ Eth }) => Eth);
  const Usdt = useSelector(({ Usdt }) => Usdt);
  const User = useSelector(({ User }) => User);
  const { selectedCoin, currentUser } = User;
  const { currencySymbol, currency } = currentUser || {};

  const coinMappings = {
    BTC: {
      coinName: "Bitcoin",
      circleColor: "yellow-circle",
      baseColor: "yellow",
      borderColor: "rgba(244, 183, 63, 1)",
      backgroundColor: "rgba(244, 183, 63, 0.2)",
      selectedReducer: Btc,
      image: BtcImg,
    },
    ETH: {
      coinName: "Ethereum",
      circleColor: "blue-circle",
      baseColor: "blue",
      borderColor: "rgba(0, 107, 250, 1)",
      backgroundColor: "rgba(0, 107, 250, 0.2)",
      selectedReducer: Eth,
      image: EthImg,
    },
    USDT: {
      coinName: "Tether",
      circleColor: "green-circle",
      baseColor: "green",
      borderColor: "rgba(75, 192, 192, 1)",
      backgroundColor: "rgba(75, 192, 192, 0.2)",
      selectedReducer: Usdt,
      image: UsdtImg,
    },
  };
  const selectedCoinData = coinMappings[selectedCoin];

  const {
    coinName,
    baseColor,
    borderColor,
    backgroundColor,
    selectedReducer,
    image,
  } = selectedCoinData;

  const {
    prices,
    labels,
    balance,
    priceNow,
    sendAmount,
    recipientAddress,
    gasPrice,
  } = selectedReducer;

  useEffect(() => {
    if (selectedCoin === "BTC") {
      dispatch(
        estimateBTCGas({
          data: {
            amount: sendAmount,
            recipientAddress,
          },
        })
      );
    } else if (selectedCoin === "ETH") {
      dispatch(
        estimateETHGas({
          data: {
            amount: sendAmount,
            recipientAddress,
          },
        })
      );
    } else if (selectedCoin === "USDT") {
      dispatch(
        estimateUSDTGas({
          data: {
            amount: sendAmount,
            recipientAddress,
          },
        })
      );
    }
    // eslint-disable-next-line
  }, []);
  // Format Balance
  let formattedBalance = parseFloat(balance).toFixed(7);
  if (isNaN(formattedBalance)) {
    formattedBalance = "0"; // Fallback if balance is not a valid number
  } else if (Number.isInteger(parseFloat(formattedBalance))) {
    formattedBalance = parseFloat(formattedBalance).toString();
  }
  // Format Transaction values
  const transactionFeeCoin = selectedCoin === "USDT" ? "ETH" : selectedCoin;
  const totalAmount =
    selectedCoin === "USDT"
      ? sendAmount
      : (parseFloat(gasPrice) + parseFloat(sendAmount)).toFixed(7);
  // Format Date
  const date = new Date();
  const formattedDate = `${date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
  })} on ${date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  })}`;

  const onContinue = () => {
    if (selectedCoin === "USDT") {
      const EthBalance = Eth.balance;
      if (EthBalance < gasPrice) {
        Swal.fire({
          icon: "error",
          title: "Error Occured",
          text: "Insufficent Ethereum Balance for Gas Fee",
        });
        return;
      }
    }

    if (balance < totalAmount) {
      Swal.fire({
        icon: "error",
        title: "Error Occured",
        text: `Insufficent ${coinName} Balance`,
      });
      return;
    }

    navigate("/confirmTransaction");
  };
  return (
    <div className="wallets-container">
      <PriceCardList />
      <CardLayout>
        <div className="coinwallet-back-btn">
          <Button variant="outlined" onClick={() => navigate(-1)}>
            <ArrowBackIcon /> Back
          </Button>
        </div>
        <div className="coinwallet-container">
          <div className="coinwallet-info-container">
            <div className="coinwallet-info review-info">
              <img src={image} className="coinwallet-img" alt="coin.png" />
              <div className="coinwallet-info-text-container">
                <div className="coinwallet-info-price">
                  <span> Price: </span> {currencySymbol}
                  {formatNumberWithCommas(priceNow)} {currency}
                </div>
                <div className="coinwallet-info-balance">
                  <span> Balance: </span>
                  {formattedBalance} {selectedCoin}
                </div>
              </div>
            </div>
            <div className="coinwallet-chart-title">
              Price of {coinName} in previous 7 days
            </div>
            <div className="coinwallet-chart">
              <Chart
                borderColor={borderColor}
                backgroundColor={backgroundColor}
                prices={prices}
                labels={labels}
              />
            </div>
          </div>
          <div className="review-transaction-details">
            <div className="review-transaction-item">
              <div className="review-transaction-title">Date:</div>
              <div className="review-transaction-value">{formattedDate}</div>
            </div>
            <div className="review-transaction-item">
              <div className="review-transaction-title">Amount:</div>
              <div className="review-transaction-value">
                {sendAmount} {selectedCoin}
              </div>
            </div>
            <div className="review-transaction-item">
              <div className="review-transaction-title">Transaction Fee:</div>
              <div className="review-transaction-value">
                {gasPrice} {transactionFeeCoin}
              </div>
            </div>
            <div className="review-transaction-item">
              <div className="review-transaction-title">Total Amount:</div>
              <div className="review-transaction-value">
                {totalAmount} {selectedCoin}
              </div>
            </div>
            <div className="review-transaction-item">
              <div className="review-transaction-title">From:</div>
              <div className="review-transaction-value">
                {selectedCoin} Wallet
              </div>
            </div>
            <div className="review-transaction-item border-bottom-none">
              <div className="review-transaction-title">To:</div>
              <div className="review-transaction-value">{recipientAddress}</div>
            </div>
            <div className="review-btn-container">
              <Button
                variant="contained"
                className={`continue ${baseColor}`}
                onClick={onContinue}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </CardLayout>
    </div>
  );
};

export default withDashboard(ReviewTransaction);
