import React from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import "./index.css";

import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const ResetPasswordStep = ({
  setStep,
  onChange,
  password,
  confirmPassword,
  showPassword,
  setshowPassword,
  errors,
  onResetClick,
}) => {
  return (
    <>
      <div className="login-form-item">
        <TextField
          label="Password"
          variant="standard"
          className="input"
          name={"password"}
          onChange={onChange}
          value={password}
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setshowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={errors.password}
          helperText={errors.password ? "Password is required" : ""}
        />
      </div>
      <div className="login-form-item">
        <TextField
          label="Confirm Password"
          variant="standard"
          className="input"
          name={"confirmPassword"}
          onChange={onChange}
          value={confirmPassword}
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setshowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          erro
          error={errors.confirmPassword}
          helperText={
            errors.confirmPassword || confirmPassword !== password
              ? "confirmPassword Password is required,and Must be same as Password"
              : ""
          }
        />
      </div>
      <div className="login-btn-container">
        <Button
          variant="contained"
          className="w-100 login-btn"
          onClick={onResetClick}
        >
          Verify To Reset
        </Button>
      </div>
      <Button className="mt-2" onClick={() => setStep("login")}>
        Back
      </Button>
    </>
  );
};

export default ResetPasswordStep;
