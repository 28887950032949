import React, { Fragment, useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  checkForgetOTP,
  checkOTP,
  login,
  resetPassword,
  sendOtp,
  setUser,
  setUserProfile,
} from "../../redux/actions/userAction";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { isValidEmail } from "../../utils";
import { BASE_URL } from "../../redux/actions/types.js";
import Loader from "../../components/Loader";
import axios from "axios";

const ForgetPasswordStep = ({
  onChange,
  email,
  errors,
  errorEmail,
  onForgetClick,
  setStep,
}) => {
  return (
    <>
      <div className="login-form-item">
        <TextField
          label="Email"
          variant="standard"
          className="input"
          name={"email"}
          onChange={onChange}
          value={email}
          type="email"
          error={errors.email}
          helperText={errors.email ? errorEmail : ""}
        />
      </div>
      <div className="login-btn-container">
        <Button
          variant="contained"
          className="w-100 login-btn"
          onClick={onForgetClick}
        >
          Recover Password
        </Button>
      </div>
      <Button className="mt-2" onClick={() => setStep("login")}>
        Back
      </Button>
    </>
  );
};

export default ForgetPasswordStep;
