import { Button, TextField } from "@mui/material";
import React from "react";

const PinStep = ({ code, setCode, errorCode, errors, handleClickOTP }) => {
  return (
    <>
      <>
        <div className="login-form-item">
          <TextField
            label="2FA Code"
            variant="standard"
            className="input"
            name={"code"}
            onChange={(e) => setCode(e.target.value)}
            value={code}
            type="text"
            error={errors.code}
            helperText={errors.code ? errorCode : ""}
          />
        </div>
        <div className="login-btn-container">
          <Button
            variant="contained"
            className="w-100 login-btn"
            onClick={handleClickOTP}
          >
            Verify
          </Button>
        </div>
      </>
    </>
  );
};

export default PinStep;
