import React, { Fragment, useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  checkForgetOTP,
  checkOTP,
  login,
  resetPassword,
  sendOtp,
  setUser,
  setUserProfile,
} from "../../redux/actions/userAction";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { isValidEmail } from "../../utils";
import { BASE_URL } from "../../redux/actions/types.js";
import Loader from "../../components/Loader";
import axios from "axios";
import LoginStep from "./LoginStep.jsx";
import PinStep from "./PinStep.jsx";
import ResetPasswordStep from "./ResetPasswordStep.jsx";
import ForgetPasswordStep from "./ForgetPasswordStep.jsx";

const Login = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setshowPassword] = useState(false);
  const User = useSelector(({ User }) => User);
  const { email, password, confirmPassword } = User;
  const [errors, setErrors] = useState({
    email: false,
    password: false,
    code: false,
    confirmPassword: false,
  });
  const [errorEmail, setErrorEmail] = useState("");
  const [errorCode, setErrorCode] = useState("");
  const [step, setStep] = useState("login");
  const [code, setCode] = useState("");

  const onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    dispatch(
      setUser({
        name,
        value,
      })
    );
    setErrors({ ...errors, [name]: false });
  };
  const onClick = () => {
    let newErrors = {
      email: !email,
      password: !password,
    };
    if (email.toString().trim() === "") {
      setErrorEmail("Email is requried");
      newErrors.email = true;
    } else if (!isValidEmail(email)) {
      setErrorEmail("Email is not valid");
      newErrors.email = true;
    }
    const everyFieldFilled = Object.values(newErrors).every((value) => !value);
    if (everyFieldFilled) {
      dispatch(login({ data: { email, password }, navigate, setStep }));
    } else {
      setErrors(newErrors);
    }
  };
  const onForgetClick = () => {
    let newErrors = {
      email: !email,
    };
    if (email.toString().trim() === "") {
      setErrorEmail("Email is requried");
      newErrors.email = true;
    } else if (!isValidEmail(email)) {
      setErrorEmail("Email is not valid");
      newErrors.email = true;
    }
    const everyFieldFilled = Object.values(newErrors).every((value) => !value);
    if (everyFieldFilled) {
      dispatch(sendOtp({ data: { email }, setStep }));
    } else {
      setErrors(newErrors);
    }
  };
  const onRecoveryClick = () => {
    setStep("forgetPassword");
  };
  const onClickOTP = () => {
    let newErrors = {
      email: !email,
      code: !code,
    };
    if (code.toString().trim() === "") {
      setErrorEmail("Code is requried");
      newErrors.code = true;
    } else if (email.toString().trim() === "") {
      setErrorEmail("Email is not valid");
      newErrors.email = true;
    }
    const everyFieldFilled = Object.values(newErrors).every((value) => !value);
    if (everyFieldFilled) {
      dispatch(checkOTP({ data: { email, otp: parseInt(code) }, navigate }));
      // setIsInSecondStep(true);
    } else {
      setErrors(newErrors);
    }
  };
  const onForgetClickOTP = () => {
    let newErrors = {
      email: !email,
      code: !code,
    };
    if (code.toString().trim() === "") {
      setErrorEmail("Code is requried");
      newErrors.code = true;
    } else if (email.toString().trim() === "") {
      setErrorEmail("Email is not valid");
      newErrors.email = true;
    }
    const everyFieldFilled = Object.values(newErrors).every((value) => !value);
    if (everyFieldFilled) {
      dispatch(
        checkForgetOTP({ data: { email, otp: parseInt(code) }, setStep })
      );
      // setIsInSecondStep(true);
    } else {
      setErrors(newErrors);
    }
  };
  const onResetClick = () => {
    let newErrors = {
      password: !password,
      confirmPassword: !confirmPassword,
    };
    if (confirmPassword.toString().trim() === "") {
      newErrors.confirmPassword = true;
    } else if (email.toString().trim() === "") {
      newErrors.password = true;
    }
    const everyFieldFilled = Object.values(newErrors).every((value) => !value);
    if (everyFieldFilled) {
      dispatch(
        resetPassword({
          data: { email, otp: parseInt(code), password },
          setStep,
        })
      );
      // setIsInSecondStep(true);
    } else {
      setErrors(newErrors);
    }
  };

  const getUserInfo = async () => {
    try {
      const { data } = await axios.get(`${BASE_URL}/api/user/user`, {
        withCredentials: true,
      });
      const currentUser = data.user;

      dispatch(setUser({ name: "currentUser", value: currentUser }));
      dispatch(setUserProfile(currentUser));
      setIsLoading(false);
      navigate("/profile");
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("user")) {
      getUserInfo();
    }
  }, []);
  useEffect(() => {
    dispatch(
      setUser({
        name: "password",
        value: "",
      })
    );
    dispatch(
      setUser({
        name: "confirmPassword",
        value: "",
      })
    );
    if (step !== "resetPassword") {
      setCode("");
    }
  }, [step]);

  return (
    <Fragment>
      <div className="login-container">
        <div className="login-content">
          <div className="login-card">
            <div className="login-header">
              <div className="login-header-title">
                {step === "login" && "Login"}
                {step === "pin" && "Enter Your PIN to Login"}
                {step === "pinVerify" &&
                  "Enter Your PIN to reset your password"}
                {step === "resetPassword" && "Update Password"}
                {step === "forgetPassword" && "Forget Password"}
              </div>
              <div className="login-header-desc">
                {step === "login" && "Enter Email and Password to Login"}
                {step === "pin" && "Enter Your PIN to Login"}
                {step === "pinVerify" &&
                  "Enter Your PIN to reset your password"}
                {step === "resetPassword" &&
                  "Update Password Your new password must be different from previously used password."}
                {step === "forgetPassword" &&
                  "Enter the email address associated with your account"}
              </div>
            </div>
            <div className="login-card-body">
              {step === "login" && (
                <LoginStep
                  onChange={onChange}
                  email={email}
                  password={password}
                  errors={errors}
                  errorEmail={errorEmail}
                  setErrorEmail={setErrorEmail}
                  onClick={onClick}
                  showPassword={showPassword}
                  setshowPassword={setshowPassword}
                  onRecoveryClick={onRecoveryClick}
                />
              )}{" "}
              {(step === "pin" || step === "pinVerify") && (
                <PinStep
                  onChange={onChange}
                  email={email}
                  code={code}
                  errors={errors}
                  errorEmail={errorEmail}
                  setErrorEmail={setErrorEmail}
                  handleClickOTP={
                    step === "pin" ? onClickOTP : onForgetClickOTP
                  }
                  setCode={setCode}
                />
              )}
              {step === "forgetPassword" && (
                <ForgetPasswordStep
                  onChange={onChange}
                  email={email}
                  errors={errors}
                  errorEmail={errorEmail}
                  setErrorEmail={setErrorEmail}
                  onForgetClick={onForgetClick}
                  setStep={setStep}
                />
              )}
              {step === "resetPassword" && (
                <ResetPasswordStep
                  onChange={onChange}
                  email={email}
                  code={code}
                  setStep={setStep}
                  password={password}
                  confirmPassword={confirmPassword}
                  errors={errors}
                  errorEmail={errorEmail}
                  setErrorEmail={setErrorEmail}
                  onResetClick={onResetClick}
                  setshowPassword={setshowPassword}
                  showPassword={showPassword}
                />
              )}
            </div>
            <Loader />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
