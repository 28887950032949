import React, { useState } from "react";
import "./index.css";
import withDashboard from "../../HOC/withDashboard";
import BtcImg from "../../assets/bitcoin.png";
import EthImg from "../../assets/ethereum.png";
import UsdtImg from "../../assets/usdt.png";
import QRCode from "react-qr-code";
import CardLayout from "../../components/CardLayout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PriceCardList from "../../components/PriceCardList";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setBtc } from "../../redux/actions/btcAction";
import { setETH } from "../../redux/actions/ethAction";
import { setUSDT } from "../../redux/actions/usdtAction";
import Swal from "sweetalert2";
import ViewTransactions from "../../components/ViewTransactions";
import { isValidAddress, setUser } from "../../redux/actions/userAction";
import { formatNumberWithCommas, isNumber, isValidDecimal } from "../../utils";
import { Button, TextField } from "@mui/material";
import Chart from "../../components/Chart";

const CoinWallet = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Btc = useSelector(({ Btc }) => Btc);
  const Eth = useSelector(({ Eth }) => Eth);
  const Usdt = useSelector(({ Usdt }) => Usdt);
  const User = useSelector(({ User }) => User);
  const { selectedCoin, currentUser } = User;
  const { currencySymbol, currency } = currentUser || {};
  const [errors, setErrors] = useState({
    sendAmount: false,
    recipientAddress: false,
  });
  const [errorRecipientAddress, setErrorRecipientAddress] = useState("");
  const [errorSendAmount, setErrorSendAmount] = useState("");

  const coinMappings = {
    BTC: {
      coinName: "Bitcoin",
      circleColor: "yellow-circle",
      baseColor: "yellow",
      borderColor: "rgba(244, 183, 63, 1)",
      backgroundColor: "rgba(244, 183, 63, 0.2)",
      selectedReducer: Btc,
      image: BtcImg,
    },
    ETH: {
      coinName: "Ethereum",
      circleColor: "blue-circle",
      baseColor: "blue",
      borderColor: "rgba(0, 107, 250, 1)",
      backgroundColor: "rgba(0, 107, 250, 0.2)",
      selectedReducer: Eth,
      image: EthImg,
    },
    USDT: {
      coinName: "Tether",
      circleColor: "green-circle",
      baseColor: "green",
      borderColor: "rgba(75, 192, 192, 1)",
      backgroundColor: "rgba(75, 192, 192, 0.2)",
      selectedReducer: Usdt,
      image: UsdtImg,
    },
  };
  const selectedCoinData = coinMappings[selectedCoin];

  const {
    coinName,
    baseColor,
    borderColor,
    backgroundColor,
    selectedReducer,
    image,
  } = selectedCoinData;

  const {
    prices,
    labels,
    balance,
    priceNow,
    address,
    sendAmount,
    recipientAddress,
  } = selectedReducer;

  const CopyToClipboard = () => {
    navigator.clipboard.writeText(address);
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Address was copied to clipboard",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  let formattedBalance = parseFloat(balance).toFixed(7);
  if (isNaN(formattedBalance)) {
    formattedBalance = "0"; // Fallback if balance is not a valid number
  } else if (Number.isInteger(parseFloat(formattedBalance))) {
    formattedBalance = parseFloat(formattedBalance).toString();
  }

  const onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (selectedCoin === "BTC") {
      dispatch(
        setBtc({
          name,
          value,
        })
      );
    } else if (selectedCoin === "ETH") {
      dispatch(
        setETH({
          name,
          value,
        })
      );
    } else if (selectedCoin === "USDT") {
      dispatch(
        setUSDT({
          name,
          value,
        })
      );
    }
    setErrors({ ...errors, [name]: false });
  };

  const onClick = async () => {
    if (parseFloat(sendAmount) <= parseFloat(balance) || sendAmount === "") {
      let newErrors = {
        sendAmount: !sendAmount,
        recipientAddress: !recipientAddress,
      };
      const isValid = await dispatch(
        isValidAddress({
          data: { address: recipientAddress, coin: selectedCoin },
        })
      );
      if (recipientAddress.toString().trim() === "") {
        setErrorRecipientAddress("Recipient Address is requried");
        newErrors.recipientAddress = true;
      } else if (!isValid) {
        setErrorRecipientAddress("Recipient Address is not valid");
        newErrors.recipientAddress = true;
      }

      if (sendAmount.toString().trim() === "") {
        setErrorSendAmount("Amount to Send is requried");
        newErrors.sendAmount = true;
      } else if (!isValidDecimal(sendAmount)) {
        setErrorSendAmount("Amount to Send is not valid");
        newErrors.sendAmount = true;
      } else if (parseFloat(sendAmount) <= 0) {
        setErrorSendAmount("Amount to Send must be greatar than zero");
        newErrors.sendAmount = true;
      }
      const everyFieldFilled = Object.values(newErrors).every(
        (value) => !value
      );
      if (everyFieldFilled) {
        dispatch(
          setUser({
            name: "isBuyTransaction",
            value: false,
          })
        );
        navigate("/reviewTransaction");
      } else {
        setErrors(newErrors);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Error Occured",
        text: "Insufficent Balance",
      });
    }
  };

  return (
    <div className="wallets-container">
      <PriceCardList />
      <CardLayout>
        <div className="coinwallet-back-btn">
          <Button variant="outlined" onClick={() => navigate(-1)}>
            <ArrowBackIcon /> Back
          </Button>
        </div>
        <div className="coinwallet-container">
          <div className="coinwallet-info-container">
            <div className="coinwallet-chart-title">
              Price of {coinName} in previous 7 days
            </div>
            <div className="coinwallet-chart">
              <Chart
                borderColor={borderColor}
                backgroundColor={backgroundColor}
                prices={prices}
                labels={labels}
              />
            </div>
          </div>
          <div className="coinwallet-recieve-container">
            <div className="coinwalllet-qr-container">
              <QRCode value={address} />
            </div>
            <div className="coinwallet-wallet-address-container">
              <div className="coinwallet-wallet-address-title">
                Wallet Address:
              </div>
              <div className="coinwallet-wallet-address-value"> {address}</div>
            </div>
            <div className="coinwallet-recieve-btn-container">
              <Button
                variant="contained"
                className={`coinwallet-recieve-btn ${baseColor}`}
                onClick={CopyToClipboard}
              >
                Copy Address
              </Button>
            </div>
          </div>
          <div className="coinwallet-send-container">
            <div className="coinwallet-info">
              <img src={image} className="coinwallet-img" alt="card.png" />
              <div className="coinwallet-info-text-container">
                <div className="coinwallet-info-price">
                  <span> Price: </span> {currencySymbol}
                  {formatNumberWithCommas(priceNow)} {currency}
                </div>
                <div className="coinwallet-info-balance">
                  <span> Balance: </span>
                  {formattedBalance} {selectedCoin}
                </div>
              </div>
            </div>
            <div className="coinwallet-send-title">
              Send {coinName} Transaction
            </div>
            <div className="coinwallet-input-container">
              <TextField
                type="text"
                label="Amount to Send"
                variant="outlined"
                className="input"
                name={"sendAmount"}
                value={sendAmount}
                onChange={(e) => {
                  let value = e.target.value;
                  if (/^0[^\d.]/.test(value)) {
                    value = "0";
                  }
                  if (isNumber(value)) {
                    onChange(e);
                  }
                }}
                error={errors.sendAmount}
                helperText={errors.sendAmount ? errorSendAmount : ""}
              />
            </div>
            <div className="coinwallet-input-container">
              <TextField
                type="text"
                label="Recipient Address"
                variant="outlined"
                className="input"
                name={"recipientAddress"}
                value={recipientAddress}
                onChange={onChange}
                error={errors.recipientAddress}
                helperText={
                  errors.recipientAddress ? errorRecipientAddress : ""
                }
              />
            </div>
            <div className="coinwallet-input-container">
              <Button
                variant="contained"
                className={`continue ${baseColor}`}
                onClick={onClick}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </CardLayout>
      <ViewTransactions />
    </div>
  );
};

export default withDashboard(CoinWallet);
