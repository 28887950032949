import React from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import "./index.css";

import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const LoginStep = ({
  onChange,
  onClick,
  onRecoveryClick,
  email,
  password,
  showPassword,
  setshowPassword,
  errors,
  errorEmail,
}) => {
  return (
    <>
      <>
        <div className="login-form-item">
          <TextField
            label="Email"
            variant="standard"
            className="input"
            name={"email"}
            onChange={onChange}
            value={email}
            type="email"
            error={errors.email}
            helperText={errors.email ? errorEmail : ""}
          />
        </div>
        <div className="login-form-item">
          <TextField
            label="Password"
            variant="standard"
            className="input"
            name={"password"}
            onChange={onChange}
            value={password}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setshowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={errors.password}
            helperText={errors.password ? "Password is required" : ""}
          />
        </div>
        <div className="login-btn-container">
          <Button
            variant="contained"
            className="w-100 login-btn"
            onClick={onClick}
          >
            Sign In
          </Button>
        </div>
        <Button className="mt-2" onClick={onRecoveryClick}>
          Forget Password ?
        </Button>
      </>
    </>
  );
};

export default LoginStep;
